
/** 
 * 2023-01-25 John Yee
 * npm i pdfjs-dist@2.4.456
 * 
 * I installed an old version because our node environment is old and does not
 * recognize the ?? operator, which pdfjs uses since a few releases ago.
 * I think any version that does not use ?? will work.  I did not hunt for a 
 * more recent version that does not use ??.
 */
import pdfjsLib from 'pdfjs-dist/build/pdf'

export default {
  name: 'FPCA-Review-Image',
  props: [ 'PDFBytes' ],
  async mounted () {
    await this.createFPCAFormImage ()
  },
  watch: {
    PDFBytes () {
      this.createFPCAFormImage ()
    }
  },
  methods: {
    async createFPCAFormImage () {
      //https://stackoverflow.com/questions/59638383/vue-emit-in-promise
      const self = this

      if (this.PDFBytes) {
        pdfjsLib.GlobalWorkerOptions.workerSrc = '/pdfjs-dist/build/pdf.worker.js'

        // https://stackoverflow.com/questions/12092633/pdf-js-rendering-a-pdf-file-using-a-base64-file-source-instead-of-url
        const pdfArray = this.convertDataURIToBinary(this.PDFBytes)

        const loadingTask = pdfjsLib.getDocument({data: pdfArray})
        var canvas = this.$refs['review_canvas']

        loadingTask.promise.then(
          function(pdf) {
            var pageNumber = 1

            pdf.getPage(pageNumber).then(function(page) {
              var scale = 1.5
              var viewport = page.getViewport({scale: scale})

              // Prepare canvas using PDF page dimensions
              canvas.height = viewport.height
              canvas.width = viewport.width

              // Render PDF page into canvas context
              var context = canvas.getContext('2d')
              var renderContext = {
                canvasContext: context,
                viewport: viewport
              }
              var renderTask = page.render(renderContext)
              renderTask.promise.then(function () {
                const fpcaPng = canvas.toDataURL()
                self.$emit('fpcaImage', fpcaPng)
              })
            })
          },
          function (reason) {
            // PDF loading error
            console.error(reason)
          }
        )
      } else {
        self.$emit('fpcaImage', '')
      }
    },
    convertDataURIToBinary(dataURI) {
      // https://stackoverflow.com/questions/12092633/pdf-js-rendering-a-pdf-file-using-a-base64-file-source-instead-of-url
      const BASE64_MARKER = ';base64,'
      const base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length
      const base64 = dataURI.substring(base64Index)
      const raw = window.atob(base64)
      const rawLength = raw.length
      var array = new Uint8Array(new ArrayBuffer(rawLength))

      for(var i = 0; i < rawLength; i++) {
        array[i] = raw.charCodeAt(i)
      }
      return array
    }
  },
}
